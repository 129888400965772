@import '../mixins';

.p-footer {
  position: fixed;
  z-index: 999;
  left: 14px;
  bottom: 16px;
  right: 22px;

  &.show {
    display: block;
  }

  & > .p-footer__title {
    position: absolute;
    bottom: -10px;
    right: 0;
    line-height: 11px;
    & .text {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 24px;
      transform: translate(0, -8px);
      font-size: 11px;
      @include sp {
        display: none;
      }
    }

    & .u-teamlab {
      display: inline-block;
    }
  }
}
