@import './_variables.scss';
@import './_mixins.scss';
@import './_animations.scss';
@import './items/index.scss';
@import './parts/index.scss';
@import './scenes/index.scss';
@import './utils/index.scss';

html,
body {
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
}

body {
  background: $mainColor;
  transition: background 1s ease-in-out;
  &.message {
    background: $bgColor;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: YuGothic, '游ゴシック', sans-serif;
}
