.i-face {

  &.i-face--size-default {
    & .i-face__image {
      width: 80px;
      height: 80px;
      border-radius: 40px;
    }
  }

  &.i-face--size-loading {
    & > .i-face__image {
      width: 120px;
      height: 120px;
      border-radius: 100px;
    }
  }

  &.i-face--size-footer {
    & .i-face__image {
      width: 60px;
      height: 60px;
      border-radius: 30px;
    }
  }

  &.i-face--size-message {
    @include pc {
      & .i-face__image {
        width: 100px;
        height: 100px;
        border-radius: 50px;
      }
    }
    @include sp {
      & .i-face__image {
        width: 120px;
        height: 120px;
        border-radius: 60px;
      }
    }
  }

  &.i-face--size-list {
    vertical-align: top;
    & > .i-face__image {
      vertical-align: top;
      @include pc {
        width: 180px;
        height: 180px;
        border-radius: 90px;
      }
      @include sp {
        width: 120px;
        height: 120px;
        border-radius: 60px;
      }
    }
    & > .i-face__name {
      vertical-align: top;
      @include pc {
        margin-top: 8px;
      }
      @include sp {
        margin-top: 4px;
      }
    }
  }

  & > .i-face__image {
    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.i-face--loading {
    text-align: center;

    & .i-face__name {
      margin-top: 46px;
      font-size: 24px;
      line-height: 24px;
    }

    & .i-face__team {
      margin-top: 24px;
      font-size: 16px;
      line-height: 16px;
    }

    &.i-face--leave {
      & .i-face__team {
        animation: a-leave-zoomIn .25s ease-out forwards;
      }
      & .i-face__name {
        animation: a-leave-zoomIn .25s ease-out forwards;
        animation-delay: .25s;
      }
      & .i-face__image {
        animation: a-leave-zoomIn .5s ease-out forwards;
        animation-delay: .5s;
      }
    }
  }

  &.i-face--message {
    text-align: center;
    margin: 0 auto;
    letter-spacing: 0.1em;
  }

  &.i-face--list {
    visibility: hidden;
    display: inline-block;
    letter-spacing: 0.1em;

    &.i-face--show {
      visibility: visible;

      & .i-face__image {
        position: relative;
        cursor: pointer;
        transition: all .3s ease-in;
        &:hover {
          filter: contrast(200%) saturate(50%);
        }

        &:before {
          content: '';
          display: block;
          position: absolute 0;
          background: $bgColor;

          @include pc {
            animation: a-face-list-border .25s 1 ease-out forwards;
            animation-delay: .25s;
          }

          @include sp {
            animation: a-face-list-border .5s 1 ease-out forwards;
            animation-delay: .25s;
          }

        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          @include pc {
            top: 2px;
            left: 2px;
            right: 2px;
            bottom: 2px;
          }
          @include sp {
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
          }

          background: url($bgDot) no-repeat;
          background-size: 100%;
          border-radius: 50%;

          @include pc {
            animation: a-face-list-fill 1s 1 cubic-bezier(0.19, 1, 0.22, 1) forwards;
            animation-delay: .5s;
          }
          @include sp {
            animation: a-face-list-fill 1s 1 cubic-bezier(0.19, 1, 0.22, 1) forwards;
            animation-delay: .75s;
          }

        }
      }
    }

    @include pc {
      margin: 30px;
    }
    @include sp {
      margin: 15px;
    }

  }

  &.i-face--footer {
    line-height: 12px;

    & * {
      vertical-align: bottom;
    }

    & .i-face--image {
      display: inline-block;
    }

    & .i-face__name {
      display: inline-block;
      margin-left: 14px;
      @include pc {
        transform: translate(0, 4px);
      }
      @include sp {
        transform: translate(0, 0);
      }
      font-size: 14px;
      line-height: 14px;
    }

    & .i-face__team {
      display: inline-block;
      vertical-align: bottom;
      @include pc {
        margin-left: 22px;
        transform: translate(0, -2px);
      }
      @include sp {
        position: absolute;
        left: 74px;
        bottom: 0;
      }
      font-size: 11px;
    }
  }
}

@keyframes a-face-list-fill {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -180px;
  }
}

@keyframes a-face-list-border {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}
