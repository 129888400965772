.error {
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .3);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  & > .error__text {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -60px;
    height: 120px;
    font-size: 30px;
    border-top: 1px rgba(255, 255, 255, .4) solid;
    border-bottom: 1px rgba(255, 255, 255, .4) solid;

    background: rgba(0, 0, 0, .8);
    color: rgba(255, 255, 255, .8);

    & a{
      color: rgba(255, 255, 255, .8);
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  & > .error__close {
    position: absolute;
    width: 120px;
    height: 60px;

    margin: 30px auto 0;
    border: 1px rgba(255, 255, 255, .4) solid;
    border-radius: 60px;
    background: rgba(0, 0, 0, .4);

    line-height: 60px;
    color: rgba(255, 255, 255, .8);
    cursor: pointer;
  }
}
