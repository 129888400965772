.scene {
  &.scene--loading {

    & .centerize {
      position: absolute;
      top: 50%;
      width: 100%;
      margin-top: -180px;
      padding-top: 20px;
      text-align: center;
      overflow: hidden;
    }

    &.scene--enter {
      animation: a-enter-fadeDown .5s ease forwards;
    }

    & .i-face__image {
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 150px;
        height: 150px;
        margin-top: -15px;
        margin-left: -15px;
        background: url(../../images/loading_symbol.png) center center no-repeat;
        background-size: cover;
        animation: a-loading-rotate 4s infinite linear;
      }
    }

    & .fill-out {
      position: absolute;
      top: -24px;
      left: auto;
      right: auto;
      margin-left: -186px;

      & path {
        fill: none;
        stroke: $mainColor;
        stroke-width: 26px;
        stroke-dasharray: 4005;
        stroke-dashoffset: 4005;
      }
    }

    &.scene--leave {
      & .i-face {
        animation: a-leave-fadeOut .9s ease-in forwards;
      }

      & .fill-out {
        & path {
          animation: a-fill-out 1s ease-out forwards;
        }
      }
    }
  }
}

@keyframes a-loading-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes a-fill-out {
  0% {
    stroke-dashoffset: 4005;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
