@import '../variables';
@import '../mixins';
@import '../animations';

.message-frame {

  &__border {
    //  display: none;

    pointer-events: none;

    position: fixed;
    z-index: 999;

    background-color: $mainColor;

    &--top {
      top: 0;
      width: 100%;
      height: 0;
      @include pc {
        animation: a-border-top-open-pc .6s cubic-bezier(0.19, 1, 0.22, 1) forwards;
      }
      @include sp {
        animation: a-border-top-open-sp .6s cubic-bezier(0.19, 1, 0.22, 1) forwards;
      }
      &.close {
        @include pc {
          animation: a-border-top-close-pc .6s cubic-bezier(0.19, 1, 0.22, 1) ;
        }
        @include sp {
          animation: a-border-top-close-sp .6s cubic-bezier(0.19, 1, 0.22, 1) ;
        }
      }
      @keyframes a-border-top-open-pc {
        0% {height: 0;}
        100% {height: 40px;}
      }
      @keyframes a-border-top-open-sp {
        0% {height: 0;}
        100% {height: 12px;}
      }
      @keyframes a-border-top-close-pc {
        0% {height: 40px;}
        100% {height: 0;}
      }
      @keyframes a-border-top-close-sp {
        0% {height: 12px;}
        100% {height: 0;}
      }
    }
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
    &--left,
    &--right {
      top: 0;
      bottom: 0;
      height: 150%;
      @include pc {
        animation: a-border-side-open-pc .6s cubic-bezier(0.19, 1, 0.22, 1) forwards;
      }
      @include sp {
        animation: a-border-side-open-sp .6s cubic-bezier(0.19, 1, 0.22, 1) forwards;
      }
      &.close {
        @include pc {
          animation: a-border-side-close-pc .6s cubic-bezier(0.19, 1, 0.22, 1) ;
        }
        @include sp {
          animation: a-border-side-close-sp .6s cubic-bezier(0.19, 1, 0.22, 1) ;
        }
      }
      @keyframes a-border-side-open-pc {
        0% {width: 0;}
        100% {width: 40px;}
      }
      @keyframes a-border-side-open-sp {
        0% {width: 0;}
        100% {width: 12px;}
      }
      @keyframes a-border-side-close-pc {
        0% {width: 40px;}
        100% {width: 0;}
      }
      @keyframes a-border-side-close-sp {
        0% {width: 12px;}
        100% {width: 0;}
      }
    }
    &--bottom {
      bottom: 0;
      width: 100%;
      height: 0;
      @include pc {
        animation: a-border-bottom-open-pc .6s cubic-bezier(0.19, 1, 0.22, 1) forwards;
      }
      @include sp {
        animation: a-border-bottom-open-sp .6s cubic-bezier(0.19, 1, 0.22, 1) forwards;
      }
      &.close {
        @include pc {
          animation: a-border-bottom-close-pc .6s cubic-bezier(0.19, 1, 0.22, 1) ;
        }
        @include sp {
          animation: a-border-bottom-close-sp .6s cubic-bezier(0.19, 1, 0.22, 1) ;
        }
      }
      @keyframes a-border-bottom-open-pc {
        0% {height: 0;}
        100% {height: 40px;}
      }
      @keyframes a-border-bottom-open-sp {
        0% {height: 0;}
        100% {height: 44px;}
      }
      @keyframes a-border-bottom-close-pc {
        0% {height: 40px;}
        100% {height: 0;}
      }
      @keyframes a-border-bottom-close-sp {
        0% {height: 44px;}
        100% {height: 0;}
      }
    }

    @include notext;
  }

  &__navigator {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1100;

    animation: a-navigator-open .6s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    @keyframes a-navigator-open {
      0% {
        transform: translate(100%, 0);
      }
      100% {
        transform: translate(0%, 0);
      }
    }

    &.close {
      animation: a-navigator-close .6s cubic-bezier(0.19, 1, 0.22, 1) forwards;
      @keyframes a-navigator-close {
        0% {
          transform: translate(0%, 0);
        }
        100% {
          transform: translate(100%, 0);
        }
      }
    }

    @include pc {
      width: 120px;
      height: 60px;
      line-height: 60px;

      font-size: 18px;
    }

    @include sp {
      width: 80px;
      height: 40px;
      line-height: 40px;

      font-size: 13px;
    }

    background: #000000;

    cursor: pointer;

    text-align: center;

    .current {
      color: $mainColor;
    }

    .total {
      color: $bgColor;
    }
  }

//  &.show {
//    display: block;
//  }
//  &.shown {
//    @include pc {
//      border-width: 40px;
//    }
//    @include sp {
//      border-width: 24px 24px 62px 24px;
//    }
//  }


}