@mixin notext {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

@mixin pc {
  @media screen and (min-width: 800px) {
    @content;
  }
}
@mixin sp {
  @media screen and (max-width: 800px) {
    @content;
  }
}
