@keyframes a-enter-rotate {
  0% {
    opacity: 0;
    transform: rotate(10deg) translate(30px, -100px) skewY(-10deg);
    transform-origin: right bottom;
  }
  100% {
    opacity: 1;
    transform: rotate(0deg) translate(0, 0) skewY(0deg);
    transform-origin: left top;
  }
}

@keyframes a-leave-rotate {
  0% {
    opacity: 1;
    transform: rotate(0deg);
    transform-origin: right top;
  }
  100% {
    opacity: 0;
    transform: rotate(-10deg);
    transform-origin: right top;
  }
}

@keyframes a-enter-fadeDown {
  0% {
    opacity: 0;
    transform: translate(0, -80px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes a-leave-fadeDown {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 80px);
  }
}

@keyframes a-enter-zoomOut {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes a-leave-zoomIn {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes a-enter-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes a-leave-fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes a-enter-message {
  0% {
    opacity: 0;
    transform: translate(60px, -30px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes a-leave-message {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-60px, 30px);
  }
}